<template>
  <div class="width-100">
    <div class="brdr-cl-primary mb60 bg-cl-secondary">
      <h3 class="cl-accent ml30 mt60 summary-title xspl20">
        {{ $t('Order Summary') }}
      </h3>
      <product v-for="product in productsInCartWithServices" :key="product.sku" :product="product" />
      <div v-if="!isLoading">
        <div v-if="productsInCart && productsInCart.length" class="checkout bg-cl-secondary pt10 serif cl-accent checkout-summary-text">
          <div
            v-for="(segment, index) in totalsModified"
            :key="index"
            :class="[segment.code === 'from' ? 'from' : '', segment.code === 'to' ? 'to' : '', `segment-${segment.code.toLowerCase()}`]"
            class="row checkout-summary-text-row"
            v-if="segment.code !== 'grand_total'"
          >
            <div class="col-xs cl-accent">
              <!-- TODO, this is temporary hard=coded -->
              <span v-if="segment.code === 'shipping' || segment.code === 'dropOff'">
                {{ translateParts(segment) }}
              </span>
              <span v-else>
                {{ $t(`${segment.title}`) }}
              </span>
            </div>
            <div v-if="segment.value != null" class="col-xs align-right cl-accent h4">
              <div v-if="segment.code === 'from'">
                {{ formatDate(segment.value) }}
              </div>
              <div v-else-if="segment.code === 'to'">
                {{ formatDate(segment.value) }}
              </div>
              <div v-else>
                {{ segment.value | price(storeView) }}
              </div>
            </div>
          </div>

          <div
            class="row pt20 pb0 weight-400 h3 checkout-summary-text-row"
            v-for="(segment, index) in totalsModified"
            :key="index"
            v-if="segment.code === 'grand_total'"
          >
            <div class="col-xs">
              {{ $t(`${segment.title}`) }}
            </div>
            <div class="col-xs align-right">
              {{ segment.value | price(storeView) }}
            </div>
          </div>

          <div class="row pb0 weight-400 h3 checkout-summary-text-row deposit">
            <div class="col-xs deposit-label-col">
              <span class="font-light">{{ $t('Deposit') }}</span>
              <tooltip>{{ $t('Deposit-tooltip') }}</tooltip>
            </div>
            <div class="col-xs align-right font-light">
              {{ $t('upon receipt') }}
            </div>
          </div>

          <div class="row pt0 pb20 weight-400 h3 checkout-summary-text-row">
            <div class="col-xs">
              <br>
              <span class="note">{{ $t('All prices are including VAT.') }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="loader-container" v-else>
        <div class="loader-inner-container">
          <div class="spinner relative">
            <div class="vs--loading vs__spinner" />
          </div>
        </div>
        <div class="loader-message-container mt15 py5 px15 align-center h6 cl-acent">
          {{ message }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CartSummary } from '@vue-storefront/core/modules/checkout/components/CartSummary';
import { currentStoreView } from '@vue-storefront/core/lib/multistore';
import Product from '../Product.vue';
import i18n from '@vue-storefront/i18n';

import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import globalConfig from 'config';
import Tooltip from 'theme/components/core/Tooltip.vue';
dayjs.extend(timezone)

const TIMEZONE = globalConfig.timezone;

export default {
  components: {
    Product,
    Tooltip
  },
  data () {
    return {
      isLoading: false,
      processes: 0,
      message: i18n.t('re-calculating prices'),
      productsInCartWithServices: []
    };
  },
  computed: {
    totalsModified () {
      return this.mergeShippingAndDropOff(this.totals)
    },
    storeView () {
      return currentStoreView();
    },
    activeServices () {
      return this.$store.state.additionalServices.activeServices;
    }
  },
  beforeDestroy () {
    this.$bus.$off('totals-sync-on', this.totalsLoading);
  },
  beforeMount () {
    this.$bus.$on('totals-sync-on', this.totalsLoading);
    this.totalsModified = this.mergeShippingAndDropOff(this.totals)
  },
  mounted () {
    this.syncProductsInCart();
  },
  methods: {
    mergeShippingAndDropOff (items) {
      // Find the shipping and dropOff objects
      const shippingItem = items.find(item => item.code === 'shipping');
      const dropOffItem = items.find(item => item.code === 'dropOff');

      if (shippingItem && dropOffItem) {
        // Merge the value of dropOff into shipping
        shippingItem.value += dropOffItem.value;

        // Optionally, you can adjust the title of the shipping item
        shippingItem.title += ` & ${dropOffItem.title}`;

        // Remove the dropOff item from the array
        const index = items.indexOf(dropOffItem);
        if (index > -1) {
          items.splice(index, 1);
        }
      }

      return items;
    },
    totalsLoading (status) {
      if (status) {
        this.processes++;
        this.isLoading = status;
      } else {
        this.processes--;
        if (this.processes === 0) {
          this.isLoading = status;
        }
      }
    },
    syncProductsInCart () {
      this.productsInCartWithServices = [];
      for (const product of this.productsInCart) {
        const selectedServices = [];
        for (const service of this.activeServices) {
          if (service.parentProductSku === product.sku) {
            selectedServices.push(service);
          }
        }
        this.productsInCartWithServices.push({ ...product, selectedServices });
      }
    },
    translateParts (segment) {
      const splitted = segment.title.split(' (', 3);
      if (!splitted[1]) {
        console.error('description in totals (segments) is wrong, missing () | checkout');
        splitted[1] = '';
      }
      const translatedPart = i18n.t(splitted[0]);
      const notTranslatedPart = splitted[1].split(')', 3);
      return `${translatedPart} ${notTranslatedPart[0]}`;
    },
    formatDate (date) {
      const timezoneReformat = dayjs.tz(date, TIMEZONE).locale('cs').format('LL');
      return timezoneReformat;
    }
  },
  watch: {
    activeServices: {
      deep: true,
      handler () {
        this.syncProductsInCart();
      }
    }
  },
  mixins: [CartSummary]
};
</script>

<style lang="scss" scoped>
.summary-title {
  @media (max-width: 767px) {
    margin-left: 0;
    padding-block: 20px;
    margin-block: 0;
    padding-inline: 15px;
  }
}

.width-100 {
  width: 100%;
}

span.note {
  font-size: 14px;
  font-family: 'TTCommons-Light';
}

.vs--loading .vs__spinner .on {
  opacity: 1;
}

@import '~theme/css/base/global_vars';
@import '~theme/css/helpers/functions/color';
$z-index-loader: map-get($z-index, loader);

.loader-container {
  position: relative;
  z-index: $z-index-loader;
  top: 10em;
}

.loader-inner-container {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.loader-message-container {
  border-radius: 50px;
  letter-spacing: 0.5px;
}

.justify-content-end {
  justify-content: end;
}

.spinner {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}

.vs--loading {
  opacity: 1;
}

.checkout-summary-text {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.deposit-label-col {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  .tooltip {
    display: flex;
  }
}

.checkout-summary-text-row {
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  @media (max-width: 500px) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.checkout-summary-text-row:nth-child(3) {
    margin-bottom: 2rem;
}

.segment {
  &-shipping,
  &-dropoff {
    display: none;
  }
}

</style>
